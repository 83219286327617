import React from 'react'

import { Hero } from '../../components/blog/hero'
import { PostSidebar } from '../../components/blog/post-sidebar'
import { Breadcrumbs, BreadcrumbsItem } from '../../components/breadcrumbs'
import { Container } from '../../components/container'
import SEO from '../../components/seo'
import img1 from './images/je-nutne-suplementovat-horcik.jpg'
import styles from './post-grid.module.css'

const JeNutneSuplementovatHorcik = () => {
  return (
    <div className={styles.wrapper}>
      <SEO
        title={'Je nutné suplementovat hořčík? | NutritionPro'}
        description={
          'Hořčík je esenciální minerální látkou, která se nachází ve všech tělních tkáních a podílí se na mnoha funkcích v těle. Ačkoliv je pro náš organismus velmi důležitý,  jedná se o jednu z nejvíce deficitních minerálních látek naší populace. Naše tělo si hořčík nedokáže vyprodukovat samo, je proto nutné jej přijímat potravou, případně doplňky stravy. Co tedy za nedostatkem hořčíku stojí a je nutné jej začít suplementovat?'
        }
      />
      <Container>
        <Breadcrumbs style={{ margin: '24px 0' }}>
          <BreadcrumbsItem link="/">Domu</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/posts">Blog</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/je-nutne-suplementovat-horcik">
            Je nutné suplementovat hořčík?
          </BreadcrumbsItem>
        </Breadcrumbs>
      </Container>
      <Container>
        <div className={styles.postGrid}>
          <div>
            <Hero title="Je nutné suplementovat hořčík?" date="17.04.2023" />
            <div>
              <p className={styles.postText}>
                Hořčík je <b>esenciální minerální látkou</b>, která se nachází
                ve všech tělních tkáních a podílí se na{' '}
                <b>mnoha funkcích v těle</b>. Ačkoliv je pro náš organismus
                velmi důležitý, jedná se o jednu z{' '}
                <b>nejvíce deficitních minerálních látek naší populace</b>. Naše
                tělo si hořčík nedokáže vyprodukovat samo, je proto nutné jej{' '}
                <b>přijímat potravou</b>, případně <b>doplňky stravy</b>. Co
                tedy za nedostatkem hořčíku stojí a <b>je nutné</b> jej začít{' '}
                <b>suplementovat</b>?
              </p>

              <img
                src={img1}
                className={styles.postImg}
                style={{ marginBottom: '40px' }}
                alt="potraviny obsahující hořčík"
              />

              <h5 className={styles.postTitle}>Hořčík v našem těle</h5>
              <p className={styles.postText}>
                Obecně se hořčík vyskytuje pouze ve formě hořečnatých iontů či
                vázaný v jiném komplexu v organické nebo anorganické formě. V
                lidském těle se magnézium vyskytuje <b>především v kostech</b>,
                a to až <b>60 %</b>. Zbytek najdeme v <b>tělních tekutinách</b>{' '}
                (především nitrobuněčných). Jeho metabolismus{' '}
                <b>souvisí s metabolismem draslíku</b>, pokud je tedy hladina
                hořčíku příliš nízká, není většinou možné doplnit množství
                draslíku na potřebnou hladinu. Magnézium se{' '}
                <b>vstřebává především v tenkém střevě</b> a snižuje ho
                například nadbytek vápníku, vlákniny, fosforečnanů a fytátů,
                nedostatek tuků v potravě, alkohol a některé léky. Příjem
                hořčíku u populace České republiky je o něco{' '}
                <b>menší, než by bylo potřeba</b>. Důvodem může být například{' '}
                <b>menší potřeba zeleniny</b>, případně některá onemocnění.
              </p>

              <h5 className={styles.postTitle}>Funkce Hořčíku</h5>
              <p className={styles.postText}>
                Magnézium je <b>součástí mnoha enzymů</b>, díky čemuž ovlivňuje
                například <b>energetický metabolismus</b>. Je také důležitý pro{' '}
                <b>činnost srdce</b> a <b>krevního oběhu</b>, jelikož se podílí
                na přenosu nervosvalových vzruchů. Společně s vápníkem se také
                podílí na <b>mineralizaci kostí</b> a v neposlední řadě na{' '}
                <b>přenosu svalových kontrakcí</b>, kde ho též doprovází vápník.
              </p>

              <h5 className={styles.postTitle}>Účinky hořčíku</h5>
              <p className={styles.postText}>
                Díky jeho funkcím je magnézium schopno{' '}
                <b>snižovat míru únavy a vyčerpání</b>, přispívá k normální{' '}
                <b>činnosti nervové soustavy a svalů</b>, také k elektrolytické
                rovnováze, normálnímu energetickému metabolismu a syntéze
                bílkovin, ale také k psychické pohodě. Navíc je důležité pro
                udržení <b>normálního stavu kostí a zubů</b>.
              </p>

              <h5 className={styles.postTitle}>V čem je hořčík?</h5>
              <p className={styles.postText}>
                Bohatým zdrojem hořčíku jsou <b>zelené rostliny</b> (kde se
                hořčík nachází ve formě chlorofylu), dále také{' '}
                <b>maso, vnitřnosti</b> nebo <b>minerální vody</b>. Denní
                potřeba hořčíku je u mužů cca 350 mg/den a u žen 300 mg/den. U{' '}
                <b>sportujících</b> jedinců je toto množství{' '}
                <b>mírně navýšeno</b>, kvůli vyššímu energetickému obratu.
              </p>

              <h5 className={styles.postTitle}>Příznaky nedostatku hořčíku</h5>
              <p className={styles.postText}>
                Nedostatečný příjem hořčíku ústí v <b>nespecifické příznaky</b>,
                jako je <b>únava</b>, slabost, náladovost, <b>bolesti hlavy</b>,
                nevolnost a křeče. Nedostatek se může projevit například
                nespavostí, nočním buzením kvůli křečím nebo tikem v oku.
              </p>

              <h5 className={styles.postTitle}>Vstřebatelnost hořčíku</h5>
              <p className={styles.postText}>
                Hořčík, stejně jako další minerální látky, je{' '}
                <b>nejlépe vstřebatelný v organické formě</b>. Velmi dobře se
                vstřebává <b>chlorofyl</b>, laktát, <b>citrát</b>, glukonát nebo{' '}
                <b>chelát</b>. Co se týče <b>anorganických forem</b>,
                vstřebatelnost chloridů a uhličitanů je pořád dobrá, oproti tomu
                sírany, fosfáty a oxidy se vstřebávají <b>velmi obtížně</b>.
                Takový <b>oxid hořečnatý</b>, který se běžně prodává v
                lékárnách, má vstřebatelnost <b>cca 4 %.</b> Síran hořečnatý se
                zase dříve používal jako laxativum, tedy projímadlo. Oproti tomu
                třeba <b>citrát</b> má vstřebatelnost <b>okolo 80 %</b> (pozor
                však na přebytek, který může mít projímavý účinek).
              </p>

              <h5 className={styles.postTitle}>Jaký hořčík je nejlepší?</h5>
              <p className={styles.postText}>
                V případě, že se rozhodnete <b>hořčík suplementovat</b>, ať už z
                důvodu <b>nedostatku</b> nebo proto, že váš trápí některý z{' '}
                <b>výše zmíněných problémů</b>, měli byste nejprve vědět, která
                forma je pro vás <b>nejvhodnější</b>. Teď už víte, že byste se
                měli zaměřit na hořčík v <b>organické formě</b>. Ani ty si ovšem
                nejsou úplně rovny. Každá forma hořčíku se dá nejlépe využít pro{' '}
                <b>jiný účel</b> a dokonce i v <b>jinou denní dobu</b>.
                Podívejme se tedy na 3 <b>nejlépe využitelné formy</b>. První
                formou je <b>malát hořečnatý</b>. Tuto formu hořčíku je dobré
                brát v <b>ranních a dopoledních</b> hodinách, protože jejím
                účelem je <b>dodat energii</b>. Druhou formou je{' '}
                <b>bysglycinát hořečnatý</b>, což je <b>nejuniverzálnější</b>{' '}
                forma hořčíku. Pokud vás trápí křeče nebo jste bolaví po
                tréninku, můžete se spolehnout na to, že vám poskytne velmi{' '}
                <b>rychlou úlevu</b>. Ideální je ho vzít{' '}
                <b>odpoledne nebo večer</b>, má totiž naopak{' '}
                <b>uklidňující účinek</b>. Jak už bylo zmíněno, je také dobré ho
                brát po tréninku pro <b>regeneraci</b> a doplnění zásob hořčíku
                do tkání. Napomáhá také <b>snížit hladinu kortizolu</b>, který
                se do těla vyplavuje při stresu, což je výhodné například před
                spaním. Třetí formou je <b>magnézium L-treonát</b>, který je
                ideální si vzít <b>večer</b> před tím, než půjdete spát. Tato
                forma hořčíku napomáhá <b>snížit stres a úzkosti</b>, zlepšuje
                kognitivní funkce mozku, <b>zklidňuje</b> mozek a nervovou
                soustavu a pomůže vám i se <b>dobře vyspat</b>. V menších
                dávkách neuškodí ani přes den, jelikož pomáhá snížit úzkostlivé
                stavy. Tato forma hořčíku je jediná, která se dostane{' '}
                <b>až do mozku</b>. Jak vidíte, opravdu <b>záleží</b> na tom,
                kterou formu hořčíku si vezmete a kdy podle toho,{' '}
                <b>co od něj očekáváte</b>. Tyto formy hořčíku můžete i{' '}
                <b>kombinovat</b>.
              </p>

              <h5 className={styles.postTitle}>Závěrem</h5>
              <p className={styles.postText}>
                Je zřejmé, že ať už jste aktivní sportovec či nikoliv,{' '}
                <b>hořčík zkrátka potřebujete</b>, stejně jako všichni ostatní.
                Nezáleží na věku ani na denní aktivitě. Magnézium se nachází v{' '}
                <b>běžných potravinách</b>, přesto ho má však většina lidí
                nedostatek. Pokud vás tedy trápí{' '}
                <b>únava, bolesti hlavy či svalové křeče</b>, zkuste se zaměřit
                na <b>potraviny bohaté na hořčík</b>, případně můžete zkusit
                zařadit <b>suplementy</b>. Dávejte však pozor,{' '}
                <b>co od hořčíku očekáváte</b>, jestli se chcete dobře vyspat,
                nebo naopak sršet energií.
              </p>

              <p className={styles.postText}>
                Autorka: Ing. Kristina Heřmanová
              </p>
            </div>
          </div>
          <PostSidebar />
        </div>
      </Container>
    </div>
  )
}

export default JeNutneSuplementovatHorcik
